import React, { memo, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { ErrorMessage } from 'shared/components/Form'

export const TextField = memo(
  ({ ref, label, Container = null, error, name = '', style, ...rest }) => {
    const ContainerComponent = Container || DefaultContainer

    return (
      <ContainerComponent style={style}>
        {label && <Label>{label}</Label>}
        <StyledInput ref={ref} {...rest} />
        <ErrorMessage name={name} error={error} />
      </ContainerComponent>
    )
  },
)

export const MaterialTextField = memo(
  ({ error, name = '', placeholder, onFocus, onBlur, style = {}, value, ...rest }) => {
    const legendRef = useRef()

    const inputId = useMemo(() => `input_${Math.random()}`, [])

    const onFocusLocal = () => {
      legendRef.current.style.maxWidth = '100%'
      onFocus?.()
    }

    const onBlurLocal = () => {
      if (!value) legendRef.current.style.maxWidth = '0.01px'
      onBlur?.()
    }

    return (
      <DefaultContainer style={{ width: '100%', ...style }}>
        <MaterialContainer error={error}>
          <input
            id={inputId}
            className="material-input"
            placeholder={placeholder}
            onFocus={onFocusLocal}
            onBlur={onBlurLocal}
            {...rest}
          />
          <label htmlFor={inputId} className="material-label">
            {placeholder}
          </label>

          <fieldset aria-hidden="true" className="material-fieldset">
            <legend className="material-legend" ref={legendRef}>
              <span>{placeholder}</span>
            </legend>
          </fieldset>
        </MaterialContainer>
        <ErrorMessage name={name} error={error} />
      </DefaultContainer>
    )
  },
)

const DefaultContainer = styled.div``

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`

const StyledInput = styled.input`
  padding: 10px 15px;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid ${Colors.grey25};
  color: ${Colors.grey100};
  width: 100%;
  background-color: ${Colors.white};

  &:focus {
    border: 1px solid ${Colors.blue};
  }

  &::placeholder {
    color: ${Colors.grey50};
  }
`

const MaterialContainer = styled.div(
  ({ error }) => `
  font-weight: 400;
  color: ${Colors.grey100};
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;

  .material-input {
    font-family: inherit;
    width: 100%;
    background: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    font-size: 18px;
    color: ${Colors.grey100};
    padding: 11px 15px;
    background: ${Colors.white};
    border: none;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .material-label {
      font-size: 18px;
      top: 8px;
    }

    &:focus ~ .material-label {
      color: ${error ? Colors.red : Colors.brand};
    }

    &:focus ~ .material-fieldset {
      border-color: ${error ? Colors.red : Colors.brand};
    }

    &:focus ~ .material-legend {
      max-width: 100%;
    }
  }

  .material-label {
    color: ${error ? Colors.red : Colors.grey50};
  }

  label,
  .material-input:focus ~ .material-label {
    position: absolute;
    top: -11px;
    left: 15px;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
  }

  .material-fieldset {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: ${error ? Colors.red : Colors.grey25};
    transition: border-color 0.2s;
  }

  .material-legend {
    float: unset;
    width: auto;
    overflow: hidden;
    display: block;
    padding: 0;
    height: 11px;
    font-size: 14px;
    visibility: hidden;
    max-width: 0.01px;
    transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    white-space: nowrap;

    span {
      padding: 0 5px;
    }
  }
`,
)
