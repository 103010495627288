import React, { useMemo } from 'react'

import { capitalize } from 'shared/utils/capitalize'
import { Column, Row, SubTitle, Title } from './styles'
import { formatCents } from 'shared/utils/currency'
import { formatDate } from 'shared/utils/dates'
import { minus } from 'shared/utils/number'

export const TitleSection = ({ order }) => {
  const { depositInvoice, finalInvoice } = order

  const title = useMemo(() => {
    switch (true) {
      case depositInvoice && !depositInvoice.paidAt && depositInvoice.amountInCents === 0:
        return `${order.bakery.name} has sent you an invoice. Please review details and accept.`

      case depositInvoice && !depositInvoice.paidAt:
        const depositAmount = formatCents(depositInvoice.amountInCents, order.bakery.currency)
        return `${order.bakery.name} has requested a deposit payment of ${depositAmount}`

      case finalInvoice && !finalInvoice.paidAt:
        const finalAmountCents = minus(order.totalAmountCents, depositInvoice?.amountInCents || 0)
        const finalAmount = formatCents(finalAmountCents, order.bakery.currency)
        return `${order.bakery.name} has requested a payment of ${finalAmount}`

      default:
        return `Order ${capitalize(order.state?.endsWith('Declined') ? 'declined' : order.state)}`
    }
  }, [order, depositInvoice, finalInvoice])

  return (
    <Column>
      <Row>
        <Title>{title}</Title>
      </Row>

      {!depositInvoice?.paidAt && order.depositDue && (
        <Row>
          <SubTitle>Deposit due {formatDate(order.depositDue)}</SubTitle>
        </Row>
      )}

      {depositInvoice?.paidAt && !finalInvoice?.paidAt && order.balanceDue && (
        <Row>
          <SubTitle>Payment due {formatDate(order.balanceDue)}</SubTitle>
        </Row>
      )}
    </Column>
  )
}
