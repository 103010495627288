import React, { createContext, use, useCallback, useState } from 'react'

const ImagesContext = createContext()

export const ImagesProvider = ({ children, initialValue }) => {
  const [images, setImages] = useState(initialValue ?? [])

  const replaceImage = useCallback((id, image) => {
    setImages((prevImages) => prevImages.map((i) => (i.id === id ? image : i)))
  }, [])

  const removeImage = useCallback((id) => {
    setImages((prevImages) => prevImages.filter((i) => i.id !== id))
  }, [])

  const addImage = useCallback((image) => {
    setImages((prevImages) => [image, ...prevImages])
  }, [])

  const value = {
    images,
    replaceImage,
    removeImage,
    addImage,
  }

  return <ImagesContext value={value}>{children}</ImagesContext>
}

export const useImagesContext = () => use(ImagesContext)
export const ImagesConsumer = ImagesContext.Consumer
