import { loader } from 'graphql.macro'

export const AvailabilityPreferencesFragment = loader('./AvailabilityPreferencesFragment.graphql')
export const BakeryLayoutFragment = loader('./BakeryLayoutFragment.graphql')
export const ButtonPreferencesFragment = loader('./ButtonPreferencesFragment.graphql')
export const CategoryFragment = loader('./CategoryFragment.graphql')
export const CurrencyFragment = loader('./CurrencyFragment.graphql')
export const CustomAnswerFragment = loader('./CustomAnswerFragment.graphql')
export const CustomQuestionFragment = loader('./CustomQuestionFragment.graphql')
export const CustomSectionFragment = loader('./CustomSectionFragment.graphql')
export const FaqFragment = loader('./FaqFragment.graphql')
export const GalleryImageFragment = loader('./GalleryImageFragment.graphql')
export const InspirationPhotoFragment = loader('./InspirationPhotoFragment.graphql')
export const InvoicePreviewFragment = loader('./InvoicePreviewFragment.graphql')
export const OfferingDetailsFragment = loader('./OfferingDetailsFragment.graphql')
export const OfferingFragment = loader('./OfferingFragment.graphql')
export const OfferingImageFragment = loader('./OfferingImageFragment.graphql')
export const OrderBakeryFragment = loader('./OrderBakeryFragment.graphql')
export const OrderDetailsFragment = loader('./OrderDetailsFragment.graphql')
export const OrderItemFragment = loader('./OrderItemFragment.graphql')
export const OrderLayoutFragment = loader('./OrderLayoutFragment.graphql')
export const OrderRequest = loader('./OrderRequestFragment.graphql')
export const PaymentMethodFragment = loader('./PaymentMethodFragment.graphql')
export const PremiumThemeFragment = loader('./PremiumThemeFragment.graphql')
export const ReviewFragment = loader('./ReviewFragment.graphql')
export const StorefrontAppearanceFragment = loader('./StorefrontAppearanceFragment.graphql')
export const UserFragment = loader('./UserFragment.graphql')
