import 'cleave.js/dist/addons/cleave-phone.ca'
import 'cleave.js/dist/addons/cleave-phone.gb'
import 'cleave.js/dist/addons/cleave-phone.ie'
import 'cleave.js/dist/addons/cleave-phone.nz'
import 'cleave.js/dist/addons/cleave-phone.us'
import 'react-dropdown/style.css'
import Cleave from 'cleave.js/react'
import Dropdown from 'react-dropdown'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'
import { ErrorMessage } from 'shared/components/Form'
import { gql } from '@apollo/client'
import { setCountryPlaceholder } from 'shared/utils/phone'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'

export const MobileNumberField = React.memo(
  ({ ref, label, Container = null, error, phoneCode, setPhoneCode, ...rest }) => {
    const ContainerComponent = Container || DefaultContainer

    const { data: countryCodes } = useBakesyQuery(COUNTRY_PHONE_CODES, {
      transform: (data) => data?.countryPhoneCodes,
      initialValue: [],
    })

    const { isMdUp } = useResponsiveLayout()

    const updateCountryCode = ({ value }) => {
      setPhoneCode(value)
    }

    const code = useMemo(() => {
      if (phoneCode?.code) return phoneCode.code
      return '+1'
    }, [phoneCode])

    const countryCode = useMemo(() => {
      if (phoneCode?.countryCode) return phoneCode.countryCode
      return 'US'
    }, [phoneCode])

    const flag = useMemo(() => {
      if (phoneCode?.flag) return phoneCode.flag
      return '🇺🇸'
    }, [phoneCode])

    useEffect(() => {
      if (countryCodes.length) {
        setPhoneCode(countryCodes.filter((i) => i.countryCode === countryCode)[0])
      }
    }, [countryCodes, countryCode, setPhoneCode])

    return (
      <ContainerComponent>
        {label && <Label>{label}</Label>}
        <MobileNumberContainer>
          <MobileNumberCountryContainer isMdUp={isMdUp}>
            <Dropdown
              options={countryCodes.map((i) => ({
                value: i,
                label: `${i.code} ${i.countryCode} ${i.flag}`,
              }))}
              value={{
                value: phoneCode,
                label: `${code} ${countryCode} ${flag}`,
              }}
              onChange={updateCountryCode}
            />
          </MobileNumberCountryContainer>

          <StyledInput
            placeholder={setCountryPlaceholder(countryCode)}
            inputMode="numeric"
            options={{ phone: true, phoneRegionCode: countryCode.toLowerCase() }}
            ref={ref}
            {...rest}
            isMdUp={isMdUp}
          />
        </MobileNumberContainer>

        <ErrorMessage error={error} />
      </ContainerComponent>
    )
  },
)

const DefaultContainer = styled.div``

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`

const MobileNumberCountryContainer = styled.div(
  ({ isMdUp }) => `
  border: 1px solid ${Colors.grey25};
  border-right: none;
  background-color: ${Colors.white};
  border-radius: 4px 0px 0px 4px;
  -webkit-appearance: none;
  outline: none;
  -webkit-appearance: none;
  width: ${isMdUp ? '25%' : '40%'};

  .Dropdown-root {
    height: 100%;
  }

  .Dropdown-control {
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .Dropdown-arrow {
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`,
)

const MobileNumberContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

const StyledInput = styled(Cleave)(
  ({ isMdUp }) => `
  width: ${isMdUp ? '75%;' : '60%'};
  padding: 10px 15px;
  background: none;
  box-shadow: none;
  border-radius: 0px 4px 4px 0px;
  -webkit-appearance: none;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid ${Colors.grey25};
  color: ${Colors.grey100};
  background-color: ${Colors.white};

  &:focus {
    border: 1px solid ${Colors.blue};
  }

  &::placeholder {
    color: ${Colors.grey50};
  }
`,
)

const COUNTRY_PHONE_CODES = gql`
  query CountryPhoneCodes {
    countryPhoneCodes {
      code
      name
      flag
      countryCode
    }
  }
`
